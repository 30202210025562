import { Router } from "@vaadin/router";
import { batch, computed, effect, signal } from "@lit-labs/preact-signals";
import newMessageSound from "../../../utils/assets/files/new-message-sound.mp3";
import {
  generateUsersAndFounders,
  getChatRequestQueryAndBody,
  getDmsChatsV,
  navigateToChat,
  playAudio,
  waitFor,
} from "./chatsStore.tools";
import {
  executeCreateDmsChat,
  executeCreateDmsMessage,
  executeDmsReadMessage,
  fetchDms,
  fetchHasUnreadMessages,
  fetchDmsMessages,
  fetchDmsWithQuery,
} from "./chatsStore.requests";
import { authStore } from "../../authStore";
import { isMobile } from "../../isMobile";

export function chatsStoreDms(notifications) {
  const selectedChatId = signal(null);
  const dmsChats = signal([]);
  const dmsChatsV = computed(() => getDmsChatsV(dmsChats.value));
  const chatsState = signal({
    loading: true,
    error: null,
    lastFetch: null,
    lastFetchError: null,
  });
  const chatsMessages = signal({});
  const chatsMessagesStates = signal({});
  const allParticipants = signal(new Map());
  const creatingChats = signal(new Map());
  const hasUnreadMessages = signal(false);

  listenForWindowFocusChange();

  return {
    dmsChats,
    dmsChatsV,
    chatsState,
    chatsMessages,
    chatsMessagesStates,
    allParticipants,
    listener: { onNewMessage, onNewChat },
    selectedChatId,
    hasUnreadMessages,
    cleanTempChats,

    createChat,
    startNewChat,
    loadChats,
    loadChatMessages,
    createMessage,
    replaceChatMessages,
    updateChat,
    markChatAsRead,
    replaceChats,
    updateMessagesState,
  };

  // #region Helper functions
  function replaceChats(newItems) {
    dmsChats.value = newItems;
    generateParticipantsFromChats();
  }

  function updateChat(chatId, newChat = {}, { replace = false } = {}) {
    if (!chatId || !newChat) return;

    const updatedChats = dmsChats.value.map((chat) => {
      if (chat.id === chatId) {
        if (replace) {
          return newChat;
        }

        return {
          ...chat,
          ...newChat,
        };
      }

      return chat;
    });

    replaceChats(updatedChats);
  }

  function updateChatsState(newState) {
    chatsState.value = { ...chatsState.value, ...newState };
  }

  function replaceChatMessages(chatId, messages = []) {
    const newMessages = {
      ...chatsMessages.value,
      [chatId]: messages,
    };

    chatsMessages.value = newMessages;
  }

  function updateMessagesState(chatId, newState) {
    chatsMessagesStates.value = {
      ...chatsMessagesStates.value,
      [chatId]: {
        ...(chatsMessagesStates.value[chatId] || {}),
        ...newState,
      },
    };
  }

  function updateChatMessage(
    chatId,
    messageId,
    newMessage = {},
    { replace = false } = {}
  ) {
    if (!chatId || !messageId || !newMessage || !chatsMessages.value[chatId])
      return;

    const updatedMessages = chatsMessages.value[chatId].map((message) => {
      if (message.id === messageId) {
        if (replace) {
          return newMessage;
        }

        return {
          ...message,
          ...newMessage,
        };
      }

      return message;
    });

    replaceChatMessages(chatId, updatedMessages);
  }

  function listenForWindowFocusChange() {
    effect(() => {
      const tabHasFocus = authStore.userActiveStatus.value;

      if (tabHasFocus && selectedChatId.value) {
        markChatAsRead(selectedChatId.value);
      }
    });
  }

  // #region Chats
  async function loadChats() {
    updateChatsState({ loading: true, error: null });

    const cohortId = authStore.cohort.value[0]?.id;
    const { error, data } = await fetchDms(cohortId);

    if (error) {
      updateChatsState({ loading: false, error, lastFetchError: Date.now() });
      return;
    }

    batch(() => {
      replaceChats([
        ...dmsChats.value.filter((chat) => chat.isTemp),
        ...(data || []),
      ]);
      updateChatsState({ loading: false, lastFetch: Date.now() });
    });
  }

  async function getChatByParticipant(
    toUserId,
    toFounderId,
    ignoreTempChats = false
  ) {
    try {
      // 0. check if chat already exists in local chats
      const alreadyExistingLocalChat = getExistingLocalChat();
      if (alreadyExistingLocalChat) return alreadyExistingLocalChat;

      // 1. check if chat already exists in the database
      const { query } = getChatRequestQueryAndBody(toUserId, toFounderId);
      const { error: pChatError, data: pChatData } = await fetchDmsWithQuery(
        query
      );

      if (pChatError) console.log("Error fetching chat", pChatError);

      // if chat already exists in the database, return it or
      // if threre is an error (a.k.a no data returned) do an early return
      if (!pChatData) return null;
      if (pChatData.docs?.length > 0) return pChatData.docs[0];

      return null;
    } catch (error) {
      console.log("Error getting chat from participants", error);
      return undefined;
    }

    function getExistingLocalChat() {
      return dmsChats.value.find(
        (chat) =>
          (!ignoreTempChats || !chat.isTemp) &&
          chat.participants.some((participant) =>
            [toUserId, toFounderId].includes(
              participant.founder?.id || participant.user?.id
            )
          )
      );
    }
  }

  async function createChat({ tempChatId, toUserId, toFounderId } = {}) {
    if (tempChatId && !toUserId && !toFounderId) {
      const chat = dmsChats.value.find((chat) => chat.id === tempChatId);
      if (!chat) return;

      toUserId = chat.participants[0]?.user?.id;
      toFounderId = chat.participants[0]?.founder?.id;
    }

    const creatingChatLoadingId = `${toUserId}`;

    // 0. check if chat is already being created
    if (creatingChats.value.has(creatingChatLoadingId)) return;
    creatingChats.value.set(creatingChatLoadingId, true);

    try {
      // 1. check if chat already exists
      const existingChat = await getChatByParticipant(
        toUserId,
        toFounderId,
        true
      );
      if (existingChat) return existingChat;

      // 2. in case chat doesn't exist, we create a new one and return it
      const { body } = getChatRequestQueryAndBody(toUserId, toFounderId);
      const { error, data: newChat } = await executeCreateDmsChat(body);

      if (error || !newChat) {
        console.log("Error creating chat", error, newChat);
        return;
      }

      if (tempChatId) {
        updateChat(tempChatId, newChat, { replace: true });
      } else {
        replaceChats([newChat, ...dmsChats.value]);
      }

      return newChat;
    } catch (error) {
      console.log("Error creating chat", error);
    } finally {
      creatingChats.value.delete(creatingChatLoadingId);
    }
  }

  async function startNewChat(toUserId, toFounderId) {
    // We're not allowing users to chat with themselves
    if (
      toUserId === authStore.userId.value &&
      (!toFounderId || toFounderId === authStore.founderId.value)
    )
      return;

    const existingChat = await getChatByParticipant(toUserId, toFounderId);

    if (existingChat?.id) {
      navigateToChat(existingChat.id);
      return;
    }

    const tempChatId = `${toUserId}${toFounderId ? `-${toFounderId}` : ""}`;
    const inboxNewPath = `/inbox/new/${tempChatId}`;

    Router.go(inboxNewPath);
  }

  async function markChatAsRead(chatId) {
    const chat = dmsChats.value.find((chat) => chat.id === chatId);
    if (!chat) return;

    const chatMessages = chatsMessages.value[chatId] || [];
    const lastReadMessage = chatMessages.at(-1);
    const userIdToCompare = authStore.founderId.value || authStore.userId.value;

    if (
      !lastReadMessage?.id ||
      (`${chat.lastReadMessageId}` === `${lastReadMessage?.id}` &&
        chat.unreadMessageCount === 0) ||
      // if the last message is from the current user and the chat has no unread messages, don't mark as read, BE will do it when sending the message
      (lastReadMessage?.userId === userIdToCompare &&
        chat.unreadMessageCount === 0)
    )
      return;

    const { error } = await executeDmsReadMessage(chatId, lastReadMessage.id);

    if (error) {
      console.log("Error marking chat as read", error);
      return;
    }

    updateChat(chatId, {
      lastReadMessageId: chatMessages.at(-1)?.id,
      unreadMessageCount: 0,
    });

    checkHasUnreadMessages();
  }

  function cleanTempChats(openingChatId) {
    const newChats = dmsChats.value.filter(
      (chat) =>
        !chat.isTemp ||
        chat.creatingChat ||
        (openingChatId && chat.id === openingChatId)
    );
    replaceChats(newChats);
  }

  // #region Messages
  async function loadChatMessages(chatId) {
    const chatMessagesState = chatsMessagesStates.value[chatId] || {};
    const previousPage = chatMessagesState.pagination?.previousPage;

    if (chatMessagesState.loading || previousPage === null) {
      return false;
    }

    updateMessagesState(chatId, { loading: true, error: null });

    const { error, data } = await fetchDmsMessages(chatId, previousPage);

    if (error || data?.error) {
      updateMessagesState(chatId, {
        loading: false,
        error: error || data?.error,
        lastFetchError: Date.now(),
      });
      return false;
    }

    const { messages = [], ...pagination } = data || {};

    batch(() => {
      replaceChatMessages(chatId, [
        ...messages,
        ...(chatMessagesState.lastFetch
          ? chatsMessages.value[chatId] || []
          : []),
      ]);
      updateMessagesState(chatId, {
        loading: false,
        lastFetch: Date.now(),
        lastFetchError: null,
        pagination,
      });
    });

    if (!previousPage && messages.length < 20 && pagination.previousPage) {
      loadChatMessages(chatId);
    }

    return true;
  }

  async function createMessage(messagePayload) {
    if (!messagePayload) return;

    const { error, data } = await executeCreateDmsMessage(messagePayload);

    if (error) {
      console.log("Error creating message", error);
      return;
    }

    updateChatMessage(messagePayload.chatId, messagePayload.localId, data, {
      replace: true,
    });

    updateChat(messagePayload.chatId, { lastReadMessageId: data.id });
  }

  async function checkHasUnreadMessages() {
    const cohortId = authStore.cohort.value[0]?.id;
    const { error, data } = await fetchHasUnreadMessages(cohortId);
    if (error) return;
    hasUnreadMessages.value = !!data?.dms;
  }

  // #region Participants
  function generateParticipantsFromChats() {
    const participants = dmsChats.value.reduce(
      (acc, chat) => [...acc, ...(chat.participants || [])],
      []
    );

    allParticipants.value = generateUsersAndFounders(participants);
  }

  // #region Socket listeners
  function onNewMessage(socketMessage) {
    const noMatchProgrammes =
      (socketMessage?.programmeId || "") !==
      (authStore.programmeId?.value || "");

    if (
      !socketMessage ||
      !socketMessage.id ||
      !socketMessage.text ||
      noMatchProgrammes
    )
      return;

    const { chatId, localId, id } = socketMessage;

    const localMessage =
      localId &&
      chatsMessages.value[chatId]?.find(
        (message) => message.id === localId || message.id === id
      );

    // if the message already exists locally, don't add it again
    if (localMessage) return;

    replaceChatMessages(chatId, [
      ...(chatsMessages.value[chatId] || []),
      socketMessage,
    ]);

    playAudio(newMessageSound);

    const tabHasFocus = authStore.userActiveStatus.value;

    if (!tabHasFocus || selectedChatId.value !== chatId) {
      const chat = dmsChats.value.find((chat) => chat.id === chatId);
      const selfParticipant = allParticipants.value.get(socketMessage.userId);
      const canShowToast = !location.pathname.startsWith(
        isMobile() ? `/inbox/${chatId}` : "/inbox"
      );

      updateChat(chatId, {
        unreadMessageCount: (chat?.unreadMessageCount || 0) + 1,
      });
      hasUnreadMessages.value = true;
      notifications.showNotification(
        {
          title:
            socketMessage.meta?.displayName || selfParticipant?.displayName,
          body: socketMessage.text,
          time: Number(socketMessage.time),
          icon: socketMessage.meta?.imageUrl || selfParticipant?.imageUrl,
          data: { from: "dms", url: `/inbox/${chatId}`, chatId },
          onClick: () => {
            navigateToChat(chatId);
          },
        },
        canShowToast
      );
    } else {
      markChatAsRead(chatId);
    }
  }

  function onNewChat(socketChat) {
    if (!socketChat) return;

    const chatExists = dmsChats.value.some((chat) => chat.id === socketChat.id);
    if (chatExists) return;

    batch(() => {
      replaceChats([socketChat, ...dmsChats.value]);
      replaceChatMessages(socketChat.id, []);
    });
  }
}
