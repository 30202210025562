import { LitElement, css, html } from "lit";
import "@shoelace-style/shoelace/dist/components/dialog/dialog.js";

class ConfirmModal extends LitElement {
  static styles = css`
    :host {
      flex: 1;
      display: flex;
    }

    input {
      flex: 1;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      height: 28px;
      padding: 4px 12px;
    }

    input:focus {
      outline: none;
    }
  `;

  static get properties() {
    return {
      dialogTitle: { type: String },
      dialogContent: { type: String },
      dialogCancelText: { type: String },
      dialogAcceptText: { type: String },
      dialogHideCancel: { type: Boolean },
      dialogOpenKey: { type: Number },
    };
  }

  constructor() {
    super();

    this.dialogTitle = "";
    this.dialogContent = "";
    this.dialogCancelText = "Cancel";
    this.dialogAcceptText = "Accept";
    this.dialogHideCancel = false;
    this.dialogOpenKey = 0;
    this.accept = () => {};
    this.close = () => {};
    this.cancel = () => {};

    this.dialog = null;
  }

  firstUpdated() {
    this.dialog = this.shadowRoot.querySelector("sl-dialog");

    this.dialog?.addEventListener("sl-after-hide", () => {
      this.close();
    });

    this.openDialog();
  }

  updated(changedProperties) {
    if (changedProperties.has("dialogOpenKey")) {
      this.openDialog();
    }
  }

  openDialog() {
    setTimeout(() => {
      this.dialog?.show();
    }, 0);
  }

  handleClose() {
    this.dialog?.hide();
  }

  render() {
    return html`
      <sl-dialog label="${this.dialogTitle}">
        ${this.dialogContent}
        ${!this.dialogHideCancel
          ? html`
              <sl-button
                slot="footer"
                variant="neutral"
                @click=${() => {
                  this.cancel();
                  this.handleClose();
                }}
              >
                ${this.dialogCancelText}
              </sl-button>
            `
          : null}
        <sl-button
          slot="footer"
          variant="danger"
          @click=${() => {
            this.accept();
            this.handleClose();
          }}
        >
          ${this.dialogAcceptText}
        </sl-button>
      </sl-dialog>
    `;
  }
}

customElements.define("confirm-modal", ConfirmModal);
