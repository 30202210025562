import { chatsStoreThreads } from "./chatsStore.threads";
import { chatsStoreDms } from "./chatsStore.dms";
import { chatsStoreRealtime } from "./chatsStore.realtime";
import { chatsStoreNotifications } from "./chatsStore.notifications";
import { waitFor } from "./chatsStore.tools";
import { authStore } from "../../authStore";
import { fetchHasUnreadMessages } from "./chatsStore.requests";

function createChatsStore() {
  /* Store */
  const notifications = chatsStoreNotifications();
  const threads = chatsStoreThreads(notifications);
  const dms = chatsStoreDms(notifications);
  const realtime = chatsStoreRealtime(threads, dms);

  if (authStore.userId.value) {
    checkHasUnreadMessages();
  }

  /* Return */
  return {
    dms,
    threads,
    realtime,
    notifications,
  };

  /* Common Functions */
  async function checkHasUnreadMessages() {
    await waitFor(2000);

    const cohortId = authStore.cohort.value[0]?.id;
    const { error, data } = await fetchHasUnreadMessages(cohortId);

    if (error) return;

    dms.hasUnreadMessages.value = !!data?.dms;
    threads.hasUnreadMessages.value = !!data?.threads;
  }
}

export const chatsStore = createChatsStore();
