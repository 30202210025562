import { signal, batch, computed, effect } from "@lit-labs/preact-signals";
import {
  generateUsersAndFounders,
  getItem,
  getRandomColor,
  getRXItem,
  getThreadsItemsV,
  navigateToThread,
  playAudio,
  removeRXItems,
  setItem,
  setRXItem,
} from "./chatsStore.tools";
import { RXDB_KEY, STORAGE_KEY } from "./chatsStore.const";
import {
  fetchThreadById,
  fetchThreadMessages,
  fetchThreads,
  executeUpdateThreadTitle,
  fetchThreadCategories,
  executeDeleteThread,
  executeUpdateThreadMessageText,
  executeDeleteThreadMessage,
  executeCreateThreadMessage,
  executeCreateThread,
  executeThreadReadMessage,
  fetchHasUnreadMessages,
  executeThreadTogglePinnedItem,
  fetchThreadPinnedItemIds,
  fetchThreadPinnedItems,
} from "./chatsStore.requests";
import { authStore } from "../../authStore";
import newMessageSound from "../../../utils/assets/files/new-message-sound.mp3";
import { checkIfSuperAdmin } from "../../../pages/threads/utils/helpers";

export function chatsStoreThreads(notifications) {
  const items = signal([]);
  const itemsState = signal({
    all: {
      loading: false,
      loadingMore: false,
      error: null,
      latestFetch: null,
    },
    individual: {},
  });
  const itemsMessages = signal({});
  const itemsMessageState = signal({});
  const itemsPagination = signal(getItem(STORAGE_KEY.THREADS_PAGINATION, null));
  const itemsConnected = signal([]);
  const selectedCategoryId = signal(null);
  const itemsV = computed(() =>
    getThreadsItemsV(items.value, selectedCategoryId.value)
  );

  const cachedCategories = getItem(STORAGE_KEY.THREADS_CATEGORIES, null);
  const categories = signal({
    items: cachedCategories || [],
    loading: !cachedCategories,
    error: false,
  });
  const usersAndFounders = signal(new Map());
  const replyingToThreadInfo = signal(
    getItem(STORAGE_KEY.THREADS_REPLYING, {
      currentBox: null,
      replyingBoxs: [],
    })
  );
  const renderNumber = signal(0);
  const editingThreadId = signal(null);
  const deletingThreadId = signal(null);
  const editingMessageId = signal(null);
  const deletingMessageId = signal(null);
  const isSuperAdmin = signal(checkIfSuperAdmin());
  const confirmedThreadsIdsToSubscribeTo = signal([]);
  const cachedValuesLoaded = signal(false);
  const selectedThreadId = signal(null);
  const minimizedThreads = signal({ _all: [] });
  const hasUnreadMessages = signal(false);
  const pinnedItemIds = signal([]);
  const pinnedItems = signal({ isLoading: true, items: [], error: null });
  const checkingForUnreadMessages = signal(false);
  const markingThreadAsRead = signal([]);

  if (authStore.userId.value) {
    listenForWindowFocusChange();
    loadCachedValues();
    fetchCategories();
    loadPinnedItemIds();
  }

  // #region Return
  return {
    items,
    itemsV,
    itemsState,
    itemsMessages,
    itemsMessageState,
    itemsPagination,
    itemsConnected,
    categories,
    selectedCategoryId,
    usersAndFounders,
    replyingToThreadInfo,
    renderNumber,
    editingThreadId,
    deletingThreadId,
    editingMessageId,
    deletingMessageId,
    isSuperAdmin,
    confirmedThreadsIdsToSubscribeTo,
    listener: {
      handleNewMessage,
      handleNewThread,
      handleThreadNewParticipant,
      handleThreadUpdateTitle,
      handleThreadDelete,
      handleThreadMessageUpdate,
      handleThreadMessageDelete,
    },
    cachedValuesLoaded,
    selectedThreadId,
    minimizedThreads,
    hasUnreadMessages,
    pinnedItemIds,
    pinnedItems,
    loadPinnedItems,

    replaceThreads,
    replaceThreadMessages,
    replaceThreadMessagesState,
    updateThread,
    updateThreadMessage,
    updateItemsState,
    loadAllThreads,
    loadThreadId,
    loadThreadMessages,
    updateReplyingToThreadInfo,
    updateThreadTitle,
    deleteThread,
    deleteThreadFromUI,
    updateThreadMessageText,
    deleteThreadMessage,
    deleteMessageFromUI,
    createThreadMessage,
    createThread,
    markThreadAsRead,
    pinItem,
    unpinItem,
    isItemPinned,
    togglePinnedItem,
  };

  // #region Helper functions
  function replaceThreads(newThreads) {
    items.value = newThreads;
    buildUF();
    setRXItem(RXDB_KEY.THREADS, newThreads);
  }

  function replaceThreadMessages(threadId, messages) {
    if (messages === "deleted") {
      const { [threadId]: _, ...rest } = itemsMessages.value;
      itemsMessages.value = rest;
    } else {
      itemsMessages.value = {
        ...itemsMessages.value,
        [threadId]: messages,
      };
    }
    setRXItem(
      RXDB_KEY.THREADS_MESSAGES,
      Object.entries(itemsMessages.value).map(([key, value]) => ({
        threadId: key,
        messages: value,
      }))
    );
  }

  function replaceThreadMessagesState(threadId, newState) {
    if (newState === "deleted") {
      const { [threadId]: _, ...rest } = itemsMessageState.value;
      itemsMessageState.value = rest;
    } else {
      itemsMessageState.value = {
        ...itemsMessageState.value,
        [threadId]: {
          ...itemsMessageState.value[threadId],
          ...newState,
        },
      };
    }
  }

  function updateItemsState(newState, threadId = null) {
    const key = threadId ? "individual" : "all";
    const value = threadId
      ? {
          ...itemsState.value.individual,
          [threadId]: {
            ...itemsState.value.individual[threadId],
            ...newState,
          },
        }
      : {
          ...itemsState.value.all,
          ...newState,
        };

    itemsState.value = {
      ...itemsState.value,
      [key]: value,
    };
  }

  function updateThreadMessage(
    threadId,
    messageId,
    newMessage,
    { replace = false } = {}
  ) {
    if (
      !threadId ||
      !messageId ||
      !newMessage ||
      !itemsMessages.value[threadId]
    )
      return;

    const updatedMessages = itemsMessages.value[threadId].map((message) => {
      if (message.id === messageId) {
        if (replace) {
          return newMessage;
        }

        return {
          ...message,
          ...newMessage,
        };
      }

      return message;
    });

    replaceThreadMessages(threadId, updatedMessages);
  }

  function updateThread(
    threadId,
    newThread,
    { replace = false, addIfNotExists = false } = {}
  ) {
    let updated = false;

    const updatedThreads = items.value.map((thread) => {
      if (thread.id === threadId) {
        updated = true;

        if (replace) {
          return newThread;
        }

        return {
          ...thread,
          ...newThread,
        };
      }

      return thread;
    });

    if (!updated) {
      console.warn(`THREAD STORE: Thread ${threadId} not found`);

      if (addIfNotExists) {
        updatedThreads.push(newThread);
        console.info(`THREAD STORE: Thread ${threadId} added`);
      }
    }

    replaceThreads(updatedThreads);
  }

  function updateReplyingToThreadInfo(threadId, message) {
    const isOnlyToSelectReplyingBox = typeof message === "undefined";
    const isToUnselectReplyingBox = !threadId;

    let boxs = replyingToThreadInfo.value.replyingBoxs;

    if (!isToUnselectReplyingBox) {
      const existsBox = replyingToThreadInfo.value.replyingBoxs.some(
        (box) => box.threadId === threadId
      );

      if (existsBox && !isOnlyToSelectReplyingBox) {
        boxs = boxs.map((box) =>
          box.threadId === threadId
            ? {
                ...box,
                message,
              }
            : box
        );
      }

      if (!existsBox) {
        boxs = [...boxs, { threadId, message: "" }];
      }
    }

    const currentBox = isToUnselectReplyingBox
      ? null
      : boxs.find((box) => box.threadId === threadId);

    replyingToThreadInfo.value = {
      currentBox,
      replyingBoxs: boxs,
    };

    // save asyncronously to local storage to avoid blocking the UI
    setTimeout(() => {
      setItem(STORAGE_KEY.THREADS_REPLYING, {
        ...replyingToThreadInfo.value,
        // We don't want to save the currentBox in local storage as we don't want to keep the reply box open if user refreshes the page
        currentBox: null, // TODO - check after we fully migrate threads to LIT <- as the page is not going to be reloaded when navigating from a single thread to the list of threads.
      });
    }, 0);
  }

  async function deleteThreadFromUI(threadId) {
    batch(() => {
      replaceThreads(items.value.filter((thread) => thread.id !== threadId));
      replaceThreadMessages(threadId, "deleted");
      replaceThreadMessagesState(threadId, "deleted");
    });

    if (location.pathname.endsWith(threadId)) {
      navigateToThread();
    }

    await removeRXItems(RXDB_KEY.THREADS, [threadId]);
  }

  function updateTotalMessages(threadId, num = 0) {
    const itemMessagesPagination =
      itemsMessageState.value[threadId]?.messagesPagination || {};

    replaceThreadMessagesState(threadId, {
      messagesPagination: {
        ...itemMessagesPagination,
        totalMessages: (itemMessagesPagination.totalMessages || 0) + num,
      },
    });
  }

  function deleteMessageFromUI(threadId, messageId) {
    const threadMessages = itemsMessages.value[threadId] || [];
    const updatedMessages = threadMessages.filter(
      (message) => message.id !== messageId
    );

    replaceThreadMessages(threadId, updatedMessages);
  }

  function removeDeletedThreadsFromRxDB(threads) {
    const threadsIds = threads.map((thread) => thread.id);
    const threadsToDelete = items.value
      .filter((thread) => !threadsIds.includes(thread.id))
      .map((thread) => thread.id);

    if (threadsToDelete.length) {
      removeRXItems(RXDB_KEY.THREADS, threadsToDelete);
    }
  }

  function listenForWindowFocusChange() {
    effect(() => {
      const tabHasFocus = authStore.userActiveStatus.value;

      if (tabHasFocus && itemsConnected.value.length) {
        itemsConnected.value.forEach((threadId) => markThreadAsRead(threadId));
      }
    });
  }

  // #region Cache
  async function loadCachedValues() {
    // load threads
    items.value = (await getRXItem(RXDB_KEY.THREADS)) || [];
    buildUF();

    // load thread messages
    const cachedMessages = (await getRXItem(RXDB_KEY.THREADS_MESSAGES)) || [];
    itemsMessages.value = cachedMessages.reduce(
      (acc, { threadId, messages }) => ({
        ...acc,
        [threadId]: messages,
      }),
      {}
    );

    cachedValuesLoaded.value = true;
  }

  // #region Thread messages
  async function loadThreadMessages(threadId, { forceLatestPage = true } = {}) {
    const itemMessageState = itemsMessageState.value[threadId] || {};

    if (!itemMessageState.messagesLoading) {
      replaceThreadMessagesState(threadId, {
        messagesError: null,
        messagesLoading: true,
      });
    } else if (itemMessageState.messagesLoading) {
      console.warn("Already fetching messages for this thread");
      return;
    }

    const pagToFetch = forceLatestPage
      ? "latest"
      : itemMessageState.messagesPagination?.previousPage || "latest";
    const { error, data } = await fetchThreadMessages(threadId, pagToFetch);

    if (error) {
      console.error(
        `An error occurred while fetching messages for thread ${threadId}`,
        error
      );
      batch(() => {
        replaceThreadMessagesState(threadId, {
          messagesError: true,
          messagesLoading: false,
          messagesLastFetchWError: Date.now(),
        });
      });
      return;
    }

    const { messages = [], ...pagInfo } = data;
    const filteredMessages = messages.filter(
      ({ text }) => text && text.trim().length
    );

    batch(() => {
      replaceThreadMessages(threadId, [
        ...filteredMessages,
        ...(pagToFetch !== "latest" ? itemsMessages.value[threadId] || [] : []),
      ]);
      replaceThreadMessagesState(threadId, {
        messagesLastFetch: Date.now(),
        messagesLoading: false,
        messagesPagination: pagInfo,
      });
    });
  }

  async function updateThreadMessageText(threadId, messageId, newText) {
    if (!threadId || !messageId || !newText) {
      console.error("Invalid threadId, messageId or newText");
      return;
    }

    updateThreadMessage(threadId, messageId, { text: newText });

    const { error } = await executeUpdateThreadMessageText(
      threadId,
      messageId,
      newText
    );

    if (error) {
      console.error("An error occurred while updating message text", error);
      return;
    }
  }

  async function deleteThreadMessage(threadId, messageId) {
    if (!threadId || !messageId) {
      console.error("Invalid threadId or messageId");
      return;
    }

    const { error } = await executeDeleteThreadMessage(threadId, messageId);

    if (error) {
      console.error("An error occurred while deleting message", error);
      return;
    }

    deleteMessageFromUI(threadId, messageId);
    deleteThreadIfEmpty(threadId);
  }

  async function createThreadMessage(payload) {
    if (!payload) {
      console.error("Invalid message");
      return;
    }

    const { error, data } = await executeCreateThreadMessage(payload);

    if (error) {
      console.error("An error occurred while creating message", error);
      return;
    }

    updateThreadMessage(payload.chatId, payload.localId, data, {
      replace: true,
    });
    updateTotalMessages(payload.chatId, 1);
  }

  async function markThreadAsRead(threadId) {
    const thread = items.value.find((chat) => chat.id === threadId);
    if (!thread) return;

    const chatMessages = itemsMessages.value[threadId] || [];
    const lastReadMessage = chatMessages.at(-1);
    const userIdToCompare = authStore.founderId.value || authStore.userId.value;
    const lastReadMessageId = `${lastReadMessage?.id}`;
    const threadLastReadMessageId = `${thread.lastReadMessageId}`;

    if (
      !lastReadMessageId ||
      (threadLastReadMessageId === lastReadMessageId &&
        thread.unreadMessageCount === 0) ||
      // if the last message is from the current user, don't mark as read, BE will do it when sending the message
      (lastReadMessage?.userId === userIdToCompare &&
        thread.unreadMessageCount === 0) ||
      markingThreadAsRead.value.some(
        (item) =>
          item.threadId === threadId &&
          item.lastReadMessageId === lastReadMessageId
      )
    )
      return;

    markingThreadAsRead.value = [
      ...markingThreadAsRead.value,
      { threadId, lastReadMessageId },
    ];

    const { error } = await executeThreadReadMessage(
      threadId,
      lastReadMessageId
    );

    markingThreadAsRead.value = markingThreadAsRead.value.filter(
      (item) =>
        item.threadId !== threadId ||
        item.lastReadMessageId !== lastReadMessageId
    );

    if (error) {
      console.log("Error marking thread as read", error);
      return;
    }

    updateThread(threadId, {
      lastReadMessageId: chatMessages.at(-1)?.id,
      unreadMessageCount: 0,
    });

    checkHasUnreadMessages();
  }

  async function checkHasUnreadMessages() {
    if (checkingForUnreadMessages.value) return;
    checkingForUnreadMessages.value = true;

    const cohortId = authStore.cohort.value[0]?.id;
    const { error, data } = await fetchHasUnreadMessages(cohortId);

    checkingForUnreadMessages.value = false;

    if (error) return;
    hasUnreadMessages.value = !!data?.threads;
  }

  // #region Threads
  async function loadAllThreads({ fetchNewThreadsMessages = true } = {}) {
    updateItemsState({ loading: true, error: null });

    const cohortId = authStore.cohort.value[0]?.id;
    const { error, data } = await fetchThreads(cohortId);

    // error handling
    if (error) {
      updateItemsState({ loading: false, error });
      console.error("An error occurred while fetching threads", error);
      return;
    }

    removeDeletedThreadsFromRxDB(data);

    // pagination
    /* 
      Threads are fetched in one go, so there's no need for pagination for now.
      If pagination is needed in the future, the following code can be used:
    */
    const pagInfo = {
      hasNextPage: false,
      nextPage: null,
    };
    itemsPagination.value = pagInfo;
    setItem(STORAGE_KEY.THREADS_PAGINATION, pagInfo);

    // update threads
    const newThreads = data.map((thread) => {
      const previousThread = items.value.find((t) => t.id === thread.id);

      return {
        ...thread,
        randomColor: previousThread?.randomColor || getRandomColor(),
      };
    });

    batch(() => {
      newThreads.forEach((thread) => {
        const [messagesError, messagesData] = thread.msgResponse || [];

        if (messagesError || !messagesData?.messages) {
          replaceThreadMessagesState(thread.id, {
            messagesError: true,
            messagesLoading: false,
            messagesLastFetchWError: Date.now(),
          });
          return;
        }

        const { messages = [], ...pagInfo } = messagesData || {};
        const filteredMessages = messages.filter(
          ({ text }) => text && text.trim().length
        );

        replaceThreadMessages(thread.id, filteredMessages);
        replaceThreadMessagesState(thread.id, {
          messagesLastFetch: Date.now(),
          messagesLoading: false,
          messagesPagination: pagInfo,
        });
      });

      replaceThreads(newThreads);
      updateItemsState({ loading: false, latestFetch: Date.now() });
    });
  }

  async function loadThreadId(threadId) {
    updateItemsState({ loading: true, error: null }, threadId);

    const { error, data: thread } = await fetchThreadById(threadId);

    if (error || !thread) {
      console.error("An error occurred while fetching thread", error, thread);
      updateItemsState({ loading: false, error: true }, threadId);
      return;
    }

    if (thread._deleted) {
      await deleteThreadFromUI(threadId);
      return;
    }

    batch(() => {
      const previousThread = items.value.find((t) => t.id === thread.id);

      updateThread(
        threadId,
        {
          ...thread,
          randomColor: previousThread?.randomColor || getRandomColor(),
        },
        { addIfNotExists: true }
      );
      updateItemsState({ loading: false, latestFetch: Date.now() }, threadId);
    });

    loadThreadMessages(threadId);
  }

  async function updateThreadTitle(threadId, newTitle = "") {
    if (!threadId || !newTitle) {
      console.error("Invalid threadId or newTitle");
      return;
    }

    updateThread(threadId, { title: newTitle });

    const { error, data } = await executeUpdateThreadTitle(threadId, newTitle);

    if (error) {
      console.error("An error occurred while updating thread title", error);
      return;
    }

    updateThread(threadId, { title: data?.doc?.title || "" });
  }

  async function deleteThreadIfEmpty(threadId) {
    const thread = items.value.find((chat) => chat.id === threadId);
    if (!thread) return;

    const chatMessages = itemsMessages.value[threadId] || [];
    const chatMessagesState = itemsMessageState.value[threadId] || {};
    const areMessagesLoaded = !!chatMessagesState.messagesLastFetch;

    if (!areMessagesLoaded || chatMessages.length > 0) return;

    return deleteThread(threadId);
  }

  async function deleteThread(threadId) {
    if (!threadId) {
      console.error("Invalid threadId");
      return;
    }

    const { error } = await executeDeleteThread(threadId);

    if (error) {
      console.error("An error occurred while deleting thread", error);
      return;
    }

    deleteThreadFromUI(threadId);
  }

  async function createThread(payload) {
    if (!payload) {
      console.error("Invalid thread");
      return;
    }

    const { error, data } = await executeCreateThread(payload);

    if (error) {
      console.error("An error occurred while creating thread", error);
      return null;
    }

    batch(() => {
      updateThread(payload.localId, {
        ...data,
        toConfirm: false,
      });

      confirmedThreadsIdsToSubscribeTo.value = [
        ...confirmedThreadsIdsToSubscribeTo.value,
        [payload.localId, data.id],
      ];

      replaceThreadMessages(payload.localId, "deleted");
      replaceThreadMessages(data.id, [data.message]);
      replaceThreadMessagesState(data.id, {
        messagesLastFetch: Date.now(),
        messagesPagination: { totalMessages: 1 },
      });
    });

    return data;
  }

  // #region Categories
  async function fetchCategories() {
    const { error, data } = await fetchThreadCategories();

    if (error || data?.errors) {
      console.log("Error fetching categories", error || data?.errors);
      categories.value = {
        ...categories.value,
        loading: false,
        error: true,
      };
      return;
    }

    categories.value = {
      items: data?.docs || [],
      loading: false,
      error: false,
    };

    setItem(STORAGE_KEY.THREADS_CATEGORIES, categories.value.items);
  }

  // #region Users and Founders
  function buildUF() {
    const threadsParticipants = items.value.reduce(
      (acc, thread) => [...acc, ...(thread.participants || [])],
      []
    );
    const pinnedMessagesParticipants = pinnedItems.value.items.reduce(
      (acc, item) => (item.isChat ? acc : [...acc, item.data.author]),
      []
    );

    usersAndFounders.value = generateUsersAndFounders([
      ...pinnedMessagesParticipants,
      ...threadsParticipants,
    ]);
  }

  // #region Socket listeners
  function handleNewMessage(data) {
    // if the thread's messages haven't been fetched yet, don't add the message as it will be added when the messages are fetched
    const { chatId } = data;
    const threadHasFetchedMessages =
      !!itemsMessageState.value[chatId]?.messagesLastFetch;
    if (!threadHasFetchedMessages) {
      hasUnreadMessages.value = true;
      return;
    }

    const localMessage = data.localId
      ? itemsMessages.value[chatId]?.find(
          (message) => message.id === data.localId || message.id === data.id
        )
      : null;

    // if the message already exists locally, don't add it again
    if (localMessage) return;

    replaceThreadMessages(chatId, [...itemsMessages.value[chatId], data]);
    updateTotalMessages(chatId, 1);
    playAudio(newMessageSound);

    const tabHasFocus = authStore.userActiveStatus.value;
    const isActiveThread =
      selectedThreadId.value === chatId ||
      itemsConnected.value.includes(chatId);

    if (!tabHasFocus || !isActiveThread) {
      const thread = items.value.find((thread) => thread.id === chatId);
      const selfParticipant = usersAndFounders.value.get(data.userId);
      const fromParticipantName =
        data.meta?.displayName || selfParticipant?.displayName;

      hasUnreadMessages.value = true;
      notifications.showNotification(
        {
          title: `New message in thread: ${thread.title}`,
          body: `${fromParticipantName} says: ${data.text}`,
          time: Number(data.time),
          icon: data.meta?.imageUrl || selfParticipant?.imageUrl,
          data: { from: "threads", chatId },
          onClick: () => navigateToThread(chatId),
        },
        true
      );
    } else {
      markThreadAsRead(chatId);
    }
  }

  function handleNewThread(socketThread) {
    const noMatchProgrammes =
      (socketThread?.programme?.id || "") !==
      (authStore.programmeId?.value || "");

    if (
      !socketThread ||
      !socketThread.id ||
      !socketThread.title ||
      noMatchProgrammes
    ) {
      return;
    }

    batch(() => {
      const localThread = socketThread.localId
        ? items.value.find(
            (thread) =>
              thread.id === socketThread.localId ||
              thread.id === socketThread.id
          )
        : null;

      // if the thread is already in the store is because it was created by this user
      // so we don't need to add it again and confirmation will be handled by the store
      if (localThread) return;

      replaceThreads([
        ...items.value,
        {
          ...socketThread,
          randomColor: getRandomColor(),
        },
      ]);

      if (socketThread.message) {
        replaceThreadMessages(socketThread.id, [socketThread.message]);
        replaceThreadMessagesState(socketThread.id, {
          messagesLastFetch: Date.now(),
          messagesPagination: { totalMessages: 1 },
        });
      } else {
        loadThreadMessages(socketThread.id);
      }
    });
  }

  function handleThreadNewParticipant(socketData) {
    const { chatId, participants } = socketData;

    updateThread(chatId, { participants });
  }

  function handleThreadUpdateTitle(socketData) {
    const { chatId, title } = socketData;

    updateThread(chatId, { title });
  }

  function handleThreadDelete(socketData) {
    const { chatId } = socketData;

    deleteThreadFromUI(chatId);
  }

  function handleThreadMessageUpdate(socketData) {
    const { chatId, messageId, text } = socketData;

    updateThreadMessage(chatId, messageId, { text });
  }

  function handleThreadMessageDelete(socketData) {
    const { chatId, messageId } = socketData;

    deleteMessageFromUI(chatId, messageId);
    updateTotalMessages(chatId, -1);
  }

  // #region Pinned items
  function pinItemUI(threadId, messageId) {
    if (isItemPinned(threadId, messageId)) return;

    const thread = items.value.find((item) => item.id === threadId);
    const message =
      messageId &&
      itemsMessages.value[threadId]?.find((item) => item.id === messageId);

    pinnedItemIds.value = [
      {
        chatId: threadId,
        ...(messageId ? { messageId } : {}),
      },
      ...pinnedItemIds.value,
    ];

    pinnedItems.value = {
      ...pinnedItems.value,
      items: [
        {
          data: message
            ? {
                ...message,
                chatId: threadId,
              }
            : {
                ...thread,
                randomColor: getRandomColor(),
              },
          isChat: !message,
        },
        ...pinnedItems.value.items,
      ],
    };
  }

  function unpinItemUI(threadId, messageId) {
    if (!isItemPinned(threadId, messageId)) return;

    pinnedItemIds.value = pinnedItemIds.value.filter(
      (item) =>
        item.chatId !== threadId ||
        (messageId ? item.messageId !== messageId : !!item.messageId)
    );

    pinnedItems.value = {
      ...pinnedItems.value,
      items: pinnedItems.value.items.filter(({ data }) =>
        messageId
          ? data.id !== messageId || data.chatId !== threadId
          : data.id !== threadId
      ),
    };
  }

  async function pinItem(threadId, messageId) {
    try {
      pinItemUI(threadId, messageId);
      const { error } = await executeThreadTogglePinnedItem(
        threadId,
        messageId,
        true
      );

      if (error) throw new Error(error);
    } catch (error) {
      console.error("An error occurred while pinning item", error);
      unpinItemUI(threadId, messageId);
    }
  }

  async function unpinItem(threadId, messageId) {
    try {
      unpinItemUI(threadId, messageId);
      await executeThreadTogglePinnedItem(threadId, messageId, false);
    } catch (error) {
      console.error("An error occurred while unpinning item", error);
    }
  }

  async function togglePinnedItem(threadId, messageId) {
    if (isItemPinned(threadId, messageId)) {
      await unpinItem(threadId, messageId);
    } else {
      await pinItem(threadId, messageId);
    }
  }

  function isItemPinned(threadId, messageId) {
    return pinnedItemIds.value.some(
      (item) =>
        item.chatId === threadId &&
        (messageId ? item.messageId === messageId : !item.messageId)
    );
  }

  async function loadPinnedItemIds() {
    try {
      const { error, data } = await fetchThreadPinnedItemIds();

      if (error || data.error) {
        console.error("An error occurred while fetching pinned items", error);
        return;
      }

      pinnedItemIds.value = data || [];
    } catch (error) {
      console.error("An error occurred while fetching pinned items", error);
    }
  }

  async function loadPinnedItems(setLoading = false) {
    try {
      if (setLoading) {
        pinnedItems.value = {
          items: [],
          error: null,
          isLoading: true,
        };
      }

      const { error, data } = await fetchThreadPinnedItems();

      if (data?.error || error) throw new Error(data?.error || error);

      pinnedItems.value = {
        isLoading: false,
        items: addRandomColorsToChats(),
        error: null,
      };

      buildUF();

      function addRandomColorsToChats() {
        return data.map((item) => ({
          ...item,
          data: item.isChat
            ? {
                ...item.data,
                randomColor:
                  pinnedItems.value.items.find(
                    (pinnedItem) =>
                      pinnedItem.isChat && pinnedItem.data.id === item.data.id
                  )?.data.randomColor || getRandomColor(),
              }
            : item.data,
        }));
      }
    } catch (error) {
      console.error("An error occurred while fetching pinned items", error);
      pinnedItems.value = {
        isLoading: false,
        items: [],
        error,
      };
    }
  }
}
