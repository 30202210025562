import { html, render } from "lit";
import { effect, signal } from "@lit-labs/preact-signals";
import "@shoelace-style/shoelace/dist/components/alert/alert.js";
import Comment from "../../../utils/assets/img/icon/comment.svg";
import { authStore } from "../../authStore";
import {
  getItem,
  isAppInstalled,
  navigateToChat,
  navigateToThread,
  setItem,
  supportPushNotification,
  supportServiceWorker,
  urlB64ToUint8Array,
  waitFor,
} from "./chatsStore.tools";
import "../../../../global-components/confirm-modal";
import { executeSendPushSubscriptionToServer } from "./chatsStore.requests";
import { STORAGE_KEY } from "./chatsStore.const";
import { isMobile } from "../../isMobile";

const isProductionSite = [
  "app.cohorthq.app",
  "mobile.cohorthq.app",
  "pwa.cohorthq.app",
].includes(location.hostname);
const isDemoSite = ["demo-chq.netlify.app", "demo-chq.netlify.app"].includes(
  location.hostname
);
const PUBLIC_SERVER_KEY = isProductionSite
  ? "BNOepidf3jHl8TcuNx8eNik90sWe32k3PabRVHgKjqL2y7BVm_2eH0shz9Zc2onLUBkoxZ52QGNmwX1ZYEPIOxI"
  : isDemoSite
  ? "BBvAsZK5Xk6UgjPCkOvSjGl8go2r5lBpWS6cBsibOluKU3HnurF7ltPXD0hd5QXQ0EY5EoWDj0x5zPxRjO1tss0"
  : "BIbeQUXPUtpK25UScbOUHPHuaJ0Ldg06tg20VMjlLK6MZaAsAmkLCSj7cb_G3rNusCcOe9n4CXaaotfDSqSzYJM";
const NOTIFICATION_PERMISSION = {
  DEFAULT: "default",
  GRANTED: "granted",
  DENIED: "denied",
};
const { DEFAULT, GRANTED, DENIED } = NOTIFICATION_PERMISSION;

export function chatsStoreNotifications() {
  const showAllowNotificationButton = signal(false);
  let swRegistration = null;
  let alreadyInitialized = false;

  effect(() => {
    if (authStore.userId.value && !location.pathname.startsWith("/auth"))
      return initialize();

    alreadyInitialized = false;
  });

  return {
    showNotification,
    showAllowNotificationButton,
    handleUserResponseToReceiveNotifications,
  };

  async function initialize() {
    if (alreadyInitialized) return;

    const swResult = await registerServiceWorker();
    if (swResult.error) return;

    listenForMessagesFromSW();

    if (!supportPushNotification()) return;

    const notiPermissionState = getNotificationPermission();
    showAllowNotificationButton.value = notiPermissionState !== GRANTED;

    if (notiPermissionState === DEFAULT) {
      const storedUserPermission = getItem(STORAGE_KEY.NOTIFICATION_PERMISSION);
      if (!storedUserPermission?.denied) {
        await waitFor(2000);
        askUserIfTheyWantNotifications();
      }
    }

    /**
     * Send the push subscription to the server if the user has already accepted notifications.
     * This is to ensure that the user is subscribed to push notifications even if they have not visited the site in a while.
     * Also in case we lose their subscription details for some reason.
     */
    if (notiPermissionState === GRANTED) {
      handlePushSubscription();
    }
  }

  function registerServiceWorker() {
    const serviceWorkerSupported = supportServiceWorker();
    if (!serviceWorkerSupported) return { error: "no-supported" };

    return navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        swRegistration = registration;
        return swRegistration.update();
      })
      .then(() => ({ error: null }))
      .catch((error) => {
        console.log("Service Worker registration failed", error);
        return { error };
      });
  }

  function askUserIfTheyWantNotifications() {
    const confirmModal = html`
      <confirm-modal
        dialogTitle="Notifications"
        dialogContent="Do you want to receive notifications?"
        dialogCancelText="No"
        dialogAcceptText="Yes"
        .accept=${() => handleUserResponseToReceiveNotifications(true)}
        .cancel=${() => handleUserResponseToReceiveNotifications(false)}
      ></confirm-modal>
    `;

    render(confirmModal, document.body);
  }

  async function handleUserResponseToReceiveNotifications(hasAccepted = false) {
    if (hasAccepted) {
      if (getNotificationPermission() === DENIED) {
        showNotificationUnblockTutorial();
        return;
      }

      await askNotificationPermission();

      if (getNotificationPermission() === GRANTED) {
        showAllowNotificationButton.value = false;
      }

      handlePushSubscription();
      return;
    }

    setItem(STORAGE_KEY.NOTIFICATION_PERMISSION, {
      denied: true,
      timestamp: Date.now(),
    });
    showAllowNotificationButton.value = true;
  }

  function askNotificationPermission() {
    return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    });
  }

  async function handlePushSubscription() {
    if (getNotificationPermission() !== GRANTED) return;

    const pushSubscription = await getPushSubscription();
    if (!pushSubscription) return;

    return sendSubscriptionToServer(pushSubscription);

    async function getPushSubscription() {
      const current = await getCurrentPushSubscription();
      if (current.subscription) return current.subscription;

      const subscribeResult = await subscribeAppToPush();
      if (subscribeResult.subscription) return subscribeResult.subscription;

      return null;
    }
  }

  function getCurrentPushSubscription() {
    return swRegistration.pushManager
      .getSubscription()
      .then((subscription) => ({ subscription }))
      .catch((error) => {
        console.log("Error getting push subscription", error);
        return { error };
      });
  }

  function subscribeAppToPush() {
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(PUBLIC_SERVER_KEY),
    };

    return swRegistration.pushManager
      .subscribe(subscribeOptions)
      .then((subscription) => ({ subscription }))
      .catch((error) => {
        console.log("Push subscription failed", error);
        return { error };
      });
  }

  async function sendSubscriptionToServer(subscription) {
    const isPwa = await isAppInstalled();

    const { error } = await executeSendPushSubscriptionToServer({
      isPwa,
      isMobile: isMobile(),
      subscription,
    });

    if (error) {
      console.log("Server push subscription failed", error);
      return;
    }
  }

  function showToastNotification(notification = {}) {
    if (!notification || !notification.title) return;

    const alertIcon = Object.assign(document.createElement("img"), {
      slot: "icon",
      alt: "inbox",
      style: `
        width: 42px;
        height: 42px;
        border-radius: ${notification.icon ? "50%" : "0px"};
        object-fit: cover;`,
      src: `${notification.icon || Comment}`,
      onclick: onClick,
    });

    const alertContent = Object.assign(document.createElement("div"), {
      innerHTML: `
        <strong style="font-weight: bold; font-size: 14px;">
          ${notification.title}
        </strong>
        <br />
        <div style="font-size: 12px;">
          ${escapeHtml(notification.body || "")}
        </div>
      `,
      onclick: onClick,
    });

    const alertElement = Object.assign(document.createElement("sl-alert"), {
      variant: "primary",
      closable: true,
      duration: 6000,
    });

    alertElement.append(alertIcon);
    alertElement.append(alertContent);
    document.body.append(alertElement);

    return alertElement.toast();

    function onClick() {
      notification.onClick?.();
      alertElement.hide();
    }

    function escapeHtml(html) {
      const div = document.createElement("div");
      div.textContent = html;
      return div.innerHTML;
    }
  }

  function showPushNotification(notification = {}) {
    if (!notification || !notification.title) return;

    const options = {
      body: notification.body || "",
      icon: notification.icon || Comment,
      vibrate: [100, 50, 100],
      data: notification?.data || {},
      silent: false,
      timestamp: notification.time || Date.now(),
    };

    swRegistration.showNotification(notification.title, options);
  }

  function showNotification(notification = {}, canShowToast = true) {
    if (!notification) return;

    const tabHasFocus = authStore.userActiveStatus.value;
    const hasPushPermission = getNotificationPermission() === GRANTED;

    if ((tabHasFocus || !hasPushPermission) && canShowToast) {
      return showToastNotification(notification);
    }

    if (!tabHasFocus && hasPushPermission) {
      return showPushNotification(notification);
    }
  }

  function listenForMessagesFromSW() {
    navigator.serviceWorker.addEventListener("message", (event) => {
      const { type, data } = event.data || {};
      if (type === "push_notification_clicked") {
        if (data?.from === "dms") navigateToChat(data.chatId);
        if (data?.from === "threads") navigateToThread(data.chatId);
      }
    });
  }

  function getNotificationPermission() {
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications");
      return DENIED;
    }

    return Notification.permission;
  }

  function showNotificationUnblockTutorial() {
    const confirmModal = html`
      <confirm-modal
        .dialogTitle=${"Notifications"}
        .dialogContent=${"You have blocked notifications. Unblock them in your browser settings to receive notifications."}
        .dialogHideCancel=${true}
        .dialogOpenKey=${Date.now()}
        .dialogAcceptText=${"Ok"}
      ></confirm-modal>
    `;

    render(confirmModal, document.body);
  }
}
