import { signal } from "@lit-labs/preact-signals";
import { authStore } from "../../authStore";
import { API } from "./chatsStore.const";
import { waitFor } from "./chatsStore.tools";

export function chatsStoreRealtime(threads, dms) {
  let connection = null;
  let disconnectTimer = null;

  const connected = signal(false);
  const lastConnectionTimestamp = signal(null);
  const lastDisconnectionTimestamp = signal(null);
  const showDisconnectIndicator = signal(false);
  const queue = [];

  const unsubscribe = addListeners();

  /* Return */
  return {
    connected,
    lastConnectionTimestamp,
    lastDisconnectionTimestamp,
    showDisconnectIndicator,

    unsubscribe,
  };

  /* Functions - we keep here the functions that use the socket connection */
  function emit(event, data) {
    if (connection && connected.value) {
      connection.emit(event, data);
    } else {
      queue.push({ event, data });
    }
  }

  /** if after "seconds" we are still disconnected, show the indicator of disconnection */
  function checkDisconnectionStatus(seconds = 3) {
    if (disconnectTimer) {
      clearTimeout(disconnectTimer);
    }

    disconnectTimer = setTimeout(() => {
      if (!connected.value) {
        showDisconnectIndicator.value = true;
      }
    }, seconds * 1000);
  }

  /* Listeners handlers */
  function handleConnect() {
    console.log("Connected to Socket.IO server!");

    connected.value = true;
    showDisconnectIndicator.value = false;
    lastConnectionTimestamp.value = Date.now();

    // Emit all the events that were queued while we were disconnected
    queue.forEach(({ event, data }) => {
      emit(event, data);
    });

    // Clear the queue
    queue.length = 0;
  }

  function handleDisconnect() {
    console.log("Disconnected from the Socket.IO server.");
    connected.value = false;
    lastDisconnectionTimestamp.value = Date.now();
    checkDisconnectionStatus();
  }

  /* Listeners */
  async function addListeners() {
    if (!authStore.userId.value) return () => {};

    await waitFor(2000);
    checkDisconnectionStatus();

    const { io } = await import("socket.io-client");

    connection = io(API.socketUrl, {
      query: {
        userId: authStore.userId.value,
        ...(authStore.founderId.value
          ? { founderId: authStore.founderId.value }
          : {}),
        cohortId: authStore.cohort.value[0]?.id,
      },
    });

    const on = connection.on.bind(connection);
    const off = connection.off.bind(connection);

    /* Connection */
    on("connect", handleConnect);
    on("disconnect", handleDisconnect);

    /* Threads */
    const { listener: thrsListener } = threads;

    on("thread:message-receive", thrsListener.handleNewMessage);
    on("thread:receive", thrsListener.handleNewThread);
    on("thread:new-participants", thrsListener.handleThreadNewParticipant);
    on("thread:update-title", thrsListener.handleThreadUpdateTitle);
    on("thread:delete", thrsListener.handleThreadDelete);
    on("thread:message-update", thrsListener.handleThreadMessageUpdate);
    on("thread:message-delete", thrsListener.handleThreadMessageDelete);

    /* DMs */
    const { listener: dmsListener } = dms;

    on("dm-chat:message-receive", dmsListener.onNewMessage);
    on("dm-chat:receive", dmsListener.onNewChat);

    /* Return */
    return () => {
      /* Connection */
      off("connect", handleConnect);
      off("disconnect", handleDisconnect);

      /* Threads */
      off("thread:message-receive", thrsListener.handleNewMessage);
      off("thread:receive", thrsListener.handleNewThread);
      off("thread:new-participants", thrsListener.handleThreadNewParticipant);
      off("thread:update-title", thrsListener.handleThreadUpdateTitle);
      off("thread:delete", thrsListener.handleThreadDelete);
      off("thread:message-update", thrsListener.handleThreadMessageUpdate);
      off("thread:message-delete", thrsListener.handleThreadMessageDelete);

      /* DMs */
      off("dm-chat:message-receive", dmsListener.onNewMessage);
      off("dm-chat:receive", dmsListener.onNewChat);

      /* Disconnect */
      connection.disconnect();
      connection = null;
    };
  }
}
