import { authStore, getFounderId, getId, getToken } from "../../authStore";
import { API } from "./chatsStore.const";
import { executePromise, stringifyQuery } from "./chatsStore.tools";

export function fetchThreads(cohortId) {
  const token = getToken();
  const filters = `userId=${getId()}&founderId=${getFounderId()}&cohortId=${cohortId}`;
  const url = `${API.socketUrl}/thread/list?${filters}`;

  return executePromise(
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
}

export function fetchThreadById(id) {
  return executePromise(
    fetch(`${API.url}/threads/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
  );
}

export function fetchThreadMessages(chatId, pagination = "latest") {
  return executePromise(
    fetch(
      `${API.socketUrl}/thread/messages?chatId=${chatId}&page=${pagination}`
    )
  );
}

export function fetchThreadTitleSuggestion(firstMessageText = "") {
  return executePromise(
    fetch("https://api.mistral.ai/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer rQmjufHLkQAajZaY7exdDjehNiAP5pRy",
      },
      body: JSON.stringify({
        model: "mistral-small-latest",
        messages: [
          {
            role: "user",
            content: `generate only ONE topic title for this message: ${firstMessageText}`,
          },
        ],
        temperature: 0.7,
        top_p: 1,
        max_tokens: 512,
        stream: false,
        safe_prompt: false,
        random_seed: 1337,
      }),
    })
  );
}

export function fetchThreadCategories() {
  const notDeletedFilter = "&where[_deleted][not_equals]=true";
  return executePromise(
    fetch(`${API.url}/thread-categories?${notDeletedFilter}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
  );
}

export function executeUpdateThreadTitle(threadId, title) {
  return executePromise(
    fetch(`${API.socketUrl}/thread/update-title`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chatId: threadId, title }),
    })
  );
}

export function executeDeleteThread(threadId) {
  return executePromise(
    fetch(`${API.socketUrl}/thread/delete`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chatId: threadId }),
    })
  );
}

export function executeUpdateThreadMessageText(threadId, messageId, text) {
  return executePromise(
    fetch(`${API.socketUrl}/thread/edit-message`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chatId: threadId, messageId, text }),
    })
  );
}

export function executeDeleteThreadMessage(threadId, messageId) {
  return executePromise(
    fetch(`${API.socketUrl}/thread/delete-message`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId: threadId,
        messageId,
        cohortId: authStore.cohort.value[0]?.id,
      }),
    })
  );
}

export function executeCreateThreadMessage(message) {
  return executePromise(
    fetch(`${API.socketUrl}/thread/send-message`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    })
  );
}

export function executeCreateThread(thread) {
  return executePromise(
    fetch(`${API.socketUrl}/thread/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(thread),
    })
  );
}

export function fetchDmsWithQuery(query = {}) {
  return executePromise(
    fetch(`${API.url}/chats${stringifyQuery(query, { depth: 2, limit: 0 })}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
  );
}

export function fetchDms(cohortId) {
  return executePromise(
    fetch(
      `${
        API.socketUrl
      }/dm-chat/list?userId=${getId()}&founderId=${getFounderId()}&cohortId=${cohortId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  );
}

export function fetchDmsMessages(chatId, page = "latest") {
  return executePromise(
    fetch(`${API.socketUrl}/dm-chat/messages?chatId=${chatId}&page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
  );
}

export function executeCreateDmsMessage(message) {
  return executePromise(
    fetch(`${API.socketUrl}/dm-chat/send-message`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    })
  );
}

export function executeCreateDmsChat(body) {
  return executePromise(
    fetch(`${API.socketUrl}/dm-chat/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
  );
}

export function executeDmsReadMessage(chatId, messageId) {
  return executePromise(
    fetch(`${API.socketUrl}/dm-chat/read-message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        messageId,
        userId: getId(),
        founderId: getFounderId(),
        cohortId: authStore.cohort.value[0]?.id,
      }),
    })
  );
}

export function fetchHasUnreadMessages(cohortId) {
  return executePromise(
    fetch(
      `${
        API.socketUrl
      }/has-unread?userId=${getId()}&founderId=${getFounderId()}&cohortId=${cohortId}`
    )
  );
}

export function executeSendPushSubscriptionToServer({
  isPwa,
  isMobile,
  subscription,
}) {
  return executePromise(
    fetch(`${API.socketUrl}/subscribe-push-notification`, {
      method: "POST",
      body: JSON.stringify({
        isPwa,
        isMobile,
        subscription,
        userId: getId(),
        founderId: getFounderId(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
  );
}

export function executeThreadReadMessage(chatId, messageId) {
  return executePromise(
    fetch(`${API.socketUrl}/thread/read-message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        messageId,
        userId: getId(),
        founderId: getFounderId(),
        cohortId: authStore.cohort.value[0]?.id,
      }),
    })
  );
}

export function fetchThreadPinnedItemIds() {
  const cohortId = authStore.cohort.value[0]?.id;
  return executePromise(
    fetch(
      `${
        API.socketUrl
      }/thread/pinned?userId=${getId()}&founderId=${getFounderId()}&cohortId=${cohortId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  );
}

export function fetchThreadPinnedItems() {
  const cohortId = authStore.cohort.value[0]?.id;
  return executePromise(
    fetch(
      `${
        API.socketUrl
      }/thread/pinned/list?userId=${getId()}&founderId=${getFounderId()}&cohortId=${cohortId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  );
}

export function executeThreadTogglePinnedItem(
  chatId,
  messageId = null,
  forceVisibilityTo = null
) {
  return executePromise(
    fetch(`${API.socketUrl}/thread/pinned`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        messageId,
        userId: getId(),
        founderId: getFounderId(),
        cohortId: authStore.cohort.value[0]?.id,
        forceVisibilityTo,
      }),
    })
  );
}
